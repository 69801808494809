import { Box, Link, styled, Tooltip } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import cardIcon from '../../assets/cardIcon.svg'
import { useDispatch } from 'react-redux'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import DeleteIcon from '../Watchlist/DeleteIcon'
import EditWatchlistIcon from '../Watchlist/EditWatchlistIcon'
import { useTranslation } from 'react-i18next'
import HeaderToolbar from '../../components/HeaderToolbar'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import AddCardTemplate from './AddCardTemplate'
import { useHistory } from 'react-router-dom'
import { REACT_URL_ADD_CARD_TEMPLATE } from '../../actions/EndPoints'
import FilterCardTemplate from './FilterCardTemplate'
import DeleteCardTemplate from './DeleteCardTemplate'
import {
  getCardTemplateList,
  updatePrintStatus,
} from '../../slices/CardTemplate/CardTemplateSlice'
import Toast from '../../components/ToastContainer/CustomToast'
import { getSiteTypeList } from '../../slices/Site/SiteTypeSlice'
import { Switch } from '@mui/material'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../../theme/ThemeContext';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 38,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 11,
    padding: 0,
    transform: 'translateX(5px)',
    backgroundColor: '#fff',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(15px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#b0c3d4',
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#226BAB',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'transparent',
    width: 15,
    height: 15,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 11.72 11.72"><path fill="${encodeURIComponent(
        '#807c7c',
      )}" d="M4.93,6.688a.586.586,0,1,0,.586.586A.586.586,0,0,0,4.93,6.688Zm7.032-2.344h-.586V2.586A.586.586,0,0,0,10.79,2H4.93a.586.586,0,0,0-.586.586V4.344H3.758A1.758,1.758,0,0,0,2,6.1V9.618a1.758,1.758,0,0,0,1.758,1.758h.586v1.758a.586.586,0,0,0,.586.586h5.86a.586.586,0,0,0,.586-.586V11.376h.586A1.758,1.758,0,0,0,13.72,9.618V6.1A1.758,1.758,0,0,0,11.962,4.344ZM5.516,3.172H10.2V4.344H5.516Zm7.032,6.446a.586.586,0,0,1-.586.586h-.586V9.618a.586.586,0,0,0-.586-.586H4.93a.586.586,0,0,0-.586.586V10.2H3.758a.586.586,0,0,1-.586-.586V6.1a.586.586,0,0,1,.586-.586h8.2a.586.586,0,0,1,.586.586Z" transform="translate(-2 -2)" /></svg>')`,
    },
  },

  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#fff',
    borderRadius: 30 / 2,
    border: '1px solid #cdcdcd',
  },
}))

function CardTemplate() {
  const history = useHistory()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [clear, setClear] = useState(false)
  const [open, setOpen] = useState(false)
  const [openEditTemplate, setOpenEditTemplate] = useState(false)
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false)
  const [cardTemplateId, setCardTemplateId] = useState()
  const [cardTemplateName, setCardTemplateName] = useState()
  const [data, setData] = useState([])
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const { darkMode } = useContext(ThemeContext);
  const [selectedObject, setSelectedObject] = useState({
    id: '',
    name: '',
    description: '',
    companyId: '',
    visitorTypeId: '',
    print: false,
    companyLogo: true,
    companyName: true,
    profile: false,
    firstName: true,
    lastName: true,
    mobile: true,
    email: true,
    visitorType: true,
    checkInTime: true,
    qrCode: true,
    visitorSign: true,
    visitorTypeName: '',
  })

  const [filter, setFilter] = React.useState({
    id: '',
    name: '',
    description: '',
    companyId: '',
    visitorTypeId: '',
    print: false,
    companyLogo: '',
    companyName: '',
    profile: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    visitorType: '',
    checkInTime: '',
    qrCode: '',
    visitorSign: '',
  })

  const defaultFilter = {
    id: '',
    name: '',
    description: '',
    companyId: '',
    visitorTypeId: '',
    print: false,
    companyLogo: '',
    companyName: '',
    profile: false,
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    visitorType: '',
    checkInTime: '',
    qrCode: '',
    visitorSign: '',
  }

  const applyFilter = (filterData) => {
    setClear(true)
    const query = {
      name: filterData.name,
      visitorTypeId: filterData.visitorTypeId,
    }

    setFilter({
      ...filter,
      ...query,
    })
  }

  const resetFilter = () => {
    setClear(false)
    setPage(1)
    setFilter({
      ...defaultFilter,
    })
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    loadData(newPage, size)
    setRowsPerPage(size)
  }

  const handleOpen = () => {
    setOpen(true)
    history.push({
      pathname: REACT_URL_ADD_CARD_TEMPLATE,
    })
  }

  const findVisitorTypeById = (id) => {
    var visitorTypeName = ''
    data.map((visitorData) => {
      if (visitorData.id === id) {
        visitorTypeName = visitorData.name
      }
    })
    return visitorTypeName
  }

  const findVisitorTypeByName = (name) => {
    var visitorTypeId = ''
    data.map((visitorData) => {
      if (visitorData.name === name) {
        visitorTypeId = visitorData.id
      }
    })
    return visitorTypeId
  }

  useEffect(() => {
    loadData(1, rowsPerPage)
  }, [filter])

  useEffect(() => {
    loadVisitType()
  }, [])

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    }
    dispatch(getCardTemplateList(payload))
      .unwrap()
      .then((data) => {
        console.info(data)
        if ((data.code = 'CVAI0000' && data.data)) {
          setTotalRecords(data.data.totalCount)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
          setRows(data.data.visitors)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(page, rowsPerPage)
      setDel(false)
    } else {
      if (del === true) {
        loadData(page - 1, rowsPerPage)
        setDel(false)
      }
    }
  }, [del])

  const loadVisitType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }
    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          setData(data.data.visitorTypes)
        } else {
          setData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleEditCardtemplate = (params) => {
    setOpenEditTemplate(!openEditTemplate)
    history.push({
      pathname: REACT_URL_ADD_CARD_TEMPLATE,
      state: { data: params.row },
    })
  }
  const handleDeleteCardtemplate = (params) => {
    setOpenDeleteTemplate(!openDeleteTemplate)
    setCardTemplateId(params.row.id)
    setCardTemplateName(params.row.name)
  }

  const globalsearch = (searchedVal) => {
    const visitorTypeName = findVisitorTypeByName(searchedVal)
    if (visitorTypeName) {
      searchedVal = visitorTypeName
    }

    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        test.visitorTypeId.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (test.description
          ? test.description.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }

  const handleCheck = (params) => {
    var printValue = !params.row.print
    const dto = {
      id: params.row.id,
      visitorTypeId: params.row.visitorTypeId,
      name: params.row.name,
      description: params.row.description,
      companyId: params.row.companyId,
      print: printValue,
      companyLogo: params.row.companyLogo,
      companyName: params.row.companyName,
      profile: params.row.profile,
      firstName: params.row.firstName,
      lastName: params.row.lastName,
      mobile: params.row.mobile,
      email: params.row.email,
      visitorType: params.row.visitorType,
      checkInTime: params.row.checkInTime,
      qrCode: params.row.qrCode,
      visitorSign: params.row.visitorSign,
      cardorientation: params.row.cardorientation,
    }
    dispatch(updatePrintStatus(dto))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0029' && data.data)) {
          loadData(page, rowsPerPage)
        } else {
          Toast(t(data.message), 'error')
        }
      })
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },

    {
      field: 'cardname',
      headerName: t('COMMON069'),
      flex: 1,
      width: 150,
      valueGetter: (params) => params.row.name,
    },
    {
      field: 'visitorTypeId',
      headerName: t('COMMON068'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => findVisitorTypeById(params.row.visitorTypeId),
    },
    {
      field: 'description',
      headerName: t('STYPE009'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          label={t('dataTableEdit')}
          onClick={() => handleEditCardtemplate(params)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t('COMMON004')}
          onClick={() => handleDeleteCardtemplate(params)}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title={params.row.print ? t('COMMON147') : t('COMMON148')}>
              <MaterialUISwitch
                checked={params.row.print}
                onChange={() => handleCheck(params)}
              />
            </Tooltip>
          }
        />,
      ],
    },
  ])
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <HeaderToolbar
        src={cardIcon}
        onClick={handleOpen}
        title={t('ADDTEMP1')}
        tooltipTitle={t('Add Card Template')}
      />


      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        sx={{marginLeft: darkMode? '-15px' : ''}}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '1',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterCardTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          count={totalRecords}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <AddCardTemplate
          open={open}
          handleClose={() => {
            setOpen(false)
            loadData(page, rowsPerPage)
          }}
        />
      )}
      {openEditTemplate && (
        <AddCardTemplate
          selectedObject={selectedObject}
          open={openEditTemplate}
          handleClose={() => {
            setOpenEditTemplate(false)
            loadData(page, rowsPerPage)
            setSelectedObject({
              id: '',
              name: '',
              description: '',
              companyId: '',
              visitorTypeId: '',
              print: '',
              companyLogo: '',
              companyName: '',
              profile: '',
              firstName: '',
              lastName: '',
              mobile: '',
              email: '',
              visitorType: '',
              checkInTime: '',
              qrCode: '',
              visitorSign: '',
            })
          }}
        />
      )}
      {openDeleteTemplate && (
        <DeleteCardTemplate
          open={openDeleteTemplate}
          handleClose={(data) => {
            setOpenDeleteTemplate(data)
          }}
          delete={() => setDel(true)}
          DeleteTemplateId={cardTemplateId}
          DeleteTemplateName={cardTemplateName}
        />
      )}
    </>
  )
}

export default CardTemplate
