import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid, IconButton
} from '@mui/material'
import { Typography } from 'antd'
import * as React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from '../../theme/ThemeContext'
import CustomTextfield from '../CustomInputs/CustomTextfield'
import Toast from '../ToastContainer/CustomToast'
import SubmitButtons from './SubmitButtons'

export default function KeyValidityDialog(props) {
  const { darkMode } = useContext(ThemeContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const handleChange = (event) => {
    setValidity(event.target.value)
  }

  const [validity, setValidity] = React.useState(7)
  const { open } = props

  const [error, setError] = React.useState({
    validTo: false,
  })

  const handleSave = () => {
    if (validity > 14) {
      Toast(t('Cannot Exceeds more than 14 days'), 'error')
      setError({ ...error, validTo: true })
      return false
    }
    props.onSubmit(validity)
  }

  const handleClose = () => {
    props.onClose()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        PaperProps={{
          style: {
            borderRadius: '20px !important',
            minWidth: '20vw',
            backgroundColor: darkMode ? '#0F273B' : '#fff',
            color: darkMode ? '#fff' : '#434547',
          },
        }}
      >
        <DialogTitle variant="h4" onClick={handleClose}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            style={{
              color: darkMode ? '#fff' : 'black',
              fontSize: '20px',
              fontWeight: '450',
            }}
          >
            Please Enter Existing Key Validity Period In Days
          </Typography>
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('VALID_TO')}
              error={error.validTo}
              name="validTo"
              value={validity}
              handleChange={(e) => handleChange(e)}
              helperText={error.validTo}
              required={false}
              validation={'numeric'}
              autoComplete="off"
              InputProps={{
                style: {
                  color: darkMode ? '#fff' : 'black',
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
          }}
        >
          <SubmitButtons variant="contained" onClick={handleSave}>
            <Typography
              variant="body1"
              style={{ textTransform: 'none', color: 'white' }}
            >
              Submit
            </Typography>
          </SubmitButtons>
        </DialogActions>
      </Dialog>
    </>
  )
}
