import { Tooltip, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import { ReactComponent as FilterIcon } from '../../../src/assets/FilterIcon.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import CommonUtil from '../../Util/CommonUtils'
import Toast from '../../components/ToastContainer/CustomToast'
import AutocompleteComponent from '../../components/CustomInputs/AutocompleteComponent'
import { getPurposeType } from '../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice'
import CustomPhone from '../../components/CustomInputs/CustomPhone'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'
import { ThemeContext } from '../../theme/ThemeContext';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'

export default function WatchlistFilterForm(props) {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const [purposeData, setPurposeData] = useState([])
  const country = props.country
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    visitorCompanyName: '',
    contactEmail: '',
    contactPhoneNumber: '',
    purposeTypeId: '',
  })

  const handleClose = () => {
    setPayload({
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      visitorCompanyName: '',
      contactEmail: '',
      contactPhoneNumber: '',
      purposeTypeId: '',
    })
    setOpen(false)
  }

  const [error, setError] = useState({
    email: false,
    phoneNumber: false,
    firstName: false,
    lastName: false,
    visitorCompanyName: false,
    purposeTypeId: false,
    contactEmail: false,
    contactPhoneNumber: false,
  })

  const reset = () => {
    // if (
    //   payload.firstName.trim() === "" &&
    //   payload.lastName.trim() === "" &&
    //   payload.email.trim() === "" &&
    //   payload.phoneNumber.trim() === "" &&
    //   payload.purposeTypeId.trim() === "" &&
    //   payload.contactEmail.trim() === "" &&
    //   payload.contactPhoneNumber.trim() === ""
    // ) {
    //   Toast(t("COMMON027"), "error");
    //   return;
    // }

    // setPayload({
    //   email: "",
    //   phoneNumber: "",
    //   firstName: "",
    //   lastName: "",
    //   visitorCompanyName: "",
    //   purposeTypeId: "",
    //   contactEmail: "",
    //   contactPhoneNumber: "",
    // });
    setOpen(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPayload({
      ...payload,
      phoneNumber: value.toString().replace(data.dialCode, ''),
    })
  }

  const handleContactPhoneChange = (value, data, event, formattedValue) => {
    setPayload({
      ...payload,
      contactPhoneNumber: value.toString().replace(data.dialCode, ''),
    })
  }

  const handleSubmit = () => {
    if (
      payload.firstName.trim() === '' &&
      payload.lastName.trim() === '' &&
      payload.email.trim() === '' &&
      payload.phoneNumber.trim() === '' &&
      payload.contactEmail.trim() === '' &&
      payload.purposeTypeId.trim() === '' &&
      payload.contactPhoneNumber.trim() === ''
    ) {
      Toast(t('COMMON015'), 'error')
      return
    }

    // if (CommonUtil.isEmptyString(payload.contactEmail)) {
    //   Toast(t("EMAILVALIDATE"), "error");
    //   setError({ name: true });
    //   return false;
    // }

    // if (!CommonUtil.isValidEmails(payload.contactEmail)) {
    //   Toast(t("CORRECTEMAIL"), "error");
    //   setError({ name: true });
    //   return;
    // }

    props.applyFilter(payload)
    setPayload({
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      visitorCompanyName: '',
      contactEmail: '',
      contactPhoneNumber: '',
      purposeTypeId: '',
    })
    handleClose()
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    loadPurposeType()
  }

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setPurposeData(data.data.purposeTypes)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event.id,
    })
  }

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('COMMON006')}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <WatchlistFilterForm />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        // Header={t('COMMON006')}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
            {t('COMMON006')}
          </span>
        }
        acceptText={t('COMMON024')}
        cancelText={t('Cancel')}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: '16px 32px', width: '100%', backgroundColor: darkMode ? '#0F273B' : '' }}
        >
          <Grid item xs={12} sm={10.5} style={{ marginTop: '15px' }}>
            <CustomTextfield
              variant="outlined"
              label={t('COMMON020')}
              error={error.firstName}
              name="firstName"
              value={payload.firstName}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              style={{ margin: '1px' }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10.5} style={{ marginTop: '15px' }}>
            <SelfRegistrationCustomPhone
              specialLabel={t('COMMON063')}
              country={country}
              placeholder="Mobile *"
              handleChange={(value, data, event, formattedValue) =>
                handlePhoneChange(value, data, event, formattedValue)
              }
            />
          </Grid>
          <Grid item xs={12} sm={10.5} style={{ marginTop: '15px' }}>
            <CustomTextfield
              label={t('COMMON042')}
              name="email"
              value={payload.email}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10.5} style={{ marginTop: '15px' }}>
            <AutocompleteComponent
              label="Purpose Type"
              name="purposeTypeId"
              error={error.purposeTypeId}
              value={payload.purposeTypeId}
              isOptionEqualToValue={(option, value) => option.name === value}
              onSelect={(e) => onselectPurposeType(e)}
              options={purposeData}
            />
          </Grid>

          <Grid item xs={12} sm={10.5}>
            <Typography
              color={darkMode ? "#fff" : "#242424"}
              fontSize={'18px'}
              opacity={1}
              fontWeight={550}
            >
              {t('PERSONINFO')} {''}{' '}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={10.5}>
            <CustomTextfield
              label={t('WATCHLISTTEMP002')}
              name="contactEmail"
              value={payload.contactEmail}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10.5} style={{ marginTop: '15px' }}>
            <SelfRegistrationCustomPhone
              placeholder="Mobile *"
              specialLabel={t('WATCHLISTTEMP009')}
              country={country}
              handleChange={(value, data, event, formattedValue) =>
                handleContactPhoneChange(value, data, event, formattedValue)
              }
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
